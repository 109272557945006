import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, of, switchMap, take, tap } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { Intercom } from "@gtmhub/shared/intercom";
import { toggleIntercom } from "@gtmhub/shared/utils";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { ITeamsStoreState } from "@gtmhub/teams";
import { getCurrentUserId } from "@gtmhub/users";
import { IDraftGoalMetricState } from "@gtmhub/whiteboards";
import { createDraftGoal, createDraftMetric, createNewNote } from "@gtmhub/whiteboards/whiteboards.utils";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { takeOneUntilDestroyed } from "@webapp/core/rxjs-operators/take-one-until-destroyed.operator";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { SuggestedGoal, SuggestedGoalState, SuggestedObjective, UpdateSuggestedGoal } from "@webapp/goal-suggestions/models/suggested-goals.models";
import { SuggestedGoalsRepository } from "@webapp/goal-suggestions/services/suggested-goals-repository.service";
import { MetricTargetOperator } from "@webapp/okrs/metrics/models/metric.models";
import { generateAssignees } from "@webapp/platform-intelligence/quantive-plus/utils/utils";
import {
  PIKeyResultSuggestionPayload,
  PIObjectiveDescriptionSuggestionPayload,
  PIObjectiveSuggestion,
  StrategyObjectiveSuggestionPayload,
} from "@webapp/platform-intelligence/shared/models/strategic-guided-okr.models";
import { StrategicGuidedOkrFacade } from "@webapp/platform-intelligence/shared/services/strategic-guided-okr/strategic-guided-okr-facade.service";
import { Session } from "@webapp/sessions/models/sessions.model";
import { PeopleSelectorRequest } from "@webapp/shared/components/people-selector/models/models";
import dayjs from "@webapp/shared/libs/dayjs";
import { TeamBase } from "@webapp/teams/models/teams.models";
import { TopNavBarButtonsConfig } from "@webapp/top-nav-bar/models/top-nav-bar-buttons.models";
import { UiButtonBuilder } from "@webapp/top-nav-bar/utils/ui-button.builder";
import { Whiteboard } from "@webapp/whiteboards/models/whiteboard.models";
import { WhiteboardsFacade } from "@webapp/whiteboards/services/whiteboards-facade.service";
import { IQuantivePlusObjective } from "../quantive-plus/models";
import { PIStateProcessorInstanceSubType } from "../shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { IQuantivePlusMetric } from "../shared/models";
import { PiTrackingEventsEnum } from "../shared/utils/pi-tracking";
import { descriptionValidators, getMetricRowsNumber, validateWhitespaces } from "./utils";

type Stage = "initial" | "objectives" | "keyresults";

function mapSuggestedGoalStateToStage(suggestedGoalState: SuggestedGoalState): Stage {
  switch (suggestedGoalState) {
    case "initial":
      return "initial";
    case "objectivesSuggested":
      return "objectives";
    case "keyResultsSuggested":
      return "keyresults";
    default:
      return "initial";
  }
}

function mapStageToSuggestedGoalState(stage: Stage): SuggestedGoalState {
  switch (stage) {
    case "initial":
      return "initial";
    case "objectives":
      return "objectivesSuggested";
    case "keyresults":
      return "keyResultsSuggested";
    default:
      return "initial";
  }
}

function mapFormObjectivesToSuggestedObjectives(objectives, selectedIds: string[], assigneesMap: Record<string, Assignee>, teams: TeamBase[]): SuggestedObjective[] {
  const assignees = generateAssignees(selectedIds, assigneesMap, teams);

  return objectives.map((objective) => ({
    title: objective.title,
    description: objective.description,
    assignees: {
      users: assignees.users.map((u) => u.id),
      teams: assignees.teams.map((t) => t.id),
    },
    keyResults: objective.keyResults.map((kr) => ({
      title: kr.title,
      description: kr.description,
      initial: kr.initial,
      target: kr.target,
      direction: kr.direction,
      unit: kr.unit,
    })),
  }));
}

@UntilDestroy()
@Component({
  selector: "guided-okrs",
  templateUrl: "./guided-okrs.component.html",
  styleUrls: ["./guided-okrs.component.less"],
})
export class GuidedOKRsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("parentTitleEl") public parentTitleElRef: ElementRef;

  @Input() public flowName: string;
  @Input() public suggestedGoal: SuggestedGoal;
  @Input() public currentSession: Session;

  @Output() public readonly closeButtonClick = new EventEmitter<void>();

  public peopleSelectorRequestInternal;
  public selectedIds: string[] = [getCurrentUserId()];
  public suggestingObjectives = false;
  public suggestingKeyResults = false;
  public suggestingObjectivesError = false;
  public suggestingMoreObjectivesError = false;
  public addingOkrsToWhiteboardError = false;
  public selectedSession: Session;
  private isAddingToWhiteboard = false;
  public hasSuggestedKrs = false;
  public hasSuggestedObjectives = false;
  public stage: Stage = "initial";
  public showConfirmationDialog = false;
  public assigneeIdMap: Record<string, Assignee>;
  public sessions: Session[];
  public whiteboardId: string;
  public objectiveForm: FormGroup;
  public flowId: string;
  public firstPart: string;
  public secondPart: string;

  public topNavBarButtonsConfig: TopNavBarButtonsConfig = {
    buttonsConfig: {
      uiButtons: [],
    },
  };

  public documentIds: string[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private strategicGuidedOkrFacade: StrategicGuidedOkrFacade,
    private formBuilder: FormBuilder,
    private whiteboardsFacade: WhiteboardsFacade,
    private analyticsService: AnalyticsService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private suggestedGoalsRepository: SuggestedGoalsRepository
  ) {
    this.setPeopleSelectorRequest();
  }

  public ngOnInit(): void {
    this.setTopNavBarButtonsConfig();

    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ISessionsStoreState>();
    this.assigneeIdMap = state.assignees.map;
    this.sessions = state.sessions.items;

    this.objectiveForm = this.formBuilder.group({
      objectives: this.formBuilder.array([], Validators.required),
      strategy: ["", [Validators.required, Validators.maxLength(5000), validateWhitespaces]],
    });

    if (this.suggestedGoal) {
      this.stage = mapSuggestedGoalStateToStage(this.suggestedGoal.state);
      this.getStrategy.setValue(this.suggestedGoal.context);
      this.documentIds = this.suggestedGoal.documentIds || [];

      const objectives: IQuantivePlusObjective[] =
        this.suggestedGoal.suggestedObjectives?.map((suggestedObjective) => ({
          title: suggestedObjective.title,
          description: suggestedObjective.description,
          assignees: {
            users: suggestedObjective.assignees.users.map((userId) => ({ id: userId, name: this.assigneeIdMap[userId].name, team: [] })),
            teams: suggestedObjective.assignees.teams.map((teamId) => ({ id: teamId, name: this.assigneeIdMap[teamId].name })),
          },
        })) || [];

      this.buildForm(objectives);

      this.suggestedGoal.suggestedObjectives?.forEach((obj, i) => {
        const krs = this.getObjectives.at(i).get("keyResults") as FormArray;
        obj.keyResults.forEach((kr) => {
          krs.push(this.addKeyResultFormGroup(kr as IQuantivePlusMetric));
        });
      });
    }

    this.flowId = uuidv4();
    this.analyticsService.track(PiTrackingEventsEnum.PiFlowInitiated, { flowName: this.flowName, flowId: this.flowId, userId: getCurrentUserId() });
    toggleIntercom({ hideLauncher: true });
  }

  private setPeopleSelectorRequest(): void {
    this.featureTogglesFacade
      .isFeatureAvailable$(FeatureFlag.ManageOKRsGranularPermissions)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (hasManageOKRsGranularPermissionsEnabled) => {
          const requiredOwnerPermission = hasManageOKRsGranularPermissionsEnabled ? "goals:own" : "ManageGoals";
          this.peopleSelectorRequestInternal = {
            ...new PeopleSelectorRequest(),
            permissionSetting: { enabled: true, permissions: [requiredOwnerPermission] },
            hideViewOnlyUsers: hasManageOKRsGranularPermissionsEnabled,
          };
        },
      });
  }

  public ngOnDestroy(): void {
    if (this.suggestedGoal) {
      const state = reduxStoreContainer.reduxStore.getState<ITeamsStoreState>();

      this.suggestedGoalsRepository
        .updateSuggestedGoal$(this.suggestedGoal.id, {
          context: this.getStrategy.value,
          documentIds: this.documentIds,
          ownerIds: this.selectedIds,
          state: mapStageToSuggestedGoalState(this.stage),
          whiteboardId: "",
          suggestedObjectives: mapFormObjectivesToSuggestedObjectives(this.getObjectives.value, this.selectedIds, this.assigneeIdMap, state.teams.items),
        })
        .pipe(take(1))
        .subscribe();
    }

    toggleIntercom({ hideLauncher: false });
  }

  public ngAfterViewInit(): void {
    if (this.firstPart && this.secondPart) {
      this.cd.detectChanges();
    }
  }

  public openIntercom(): void {
    toggleIntercom({ hideLauncher: false });

    if (Intercom) {
      Intercom("show");
    }
  }

  public close(): void {
    this.closeButtonClick.emit();
  }

  get getStrategy(): FormControl {
    return this.objectiveForm.get("strategy") as FormControl;
  }

  get getObjectives(): FormArray {
    return this.objectiveForm.get("objectives") as FormArray;
  }

  public isEditing(): boolean {
    return this.getObjectives.value.some((obj) => obj.isEdit || obj.keyResults.some((kr) => kr.isEdit));
  }

  private addObjectiveFormGroup(obj: IQuantivePlusObjective): FormGroup {
    const assigneeIds = [...obj.assignees.users, ...obj.assignees.teams].map((assignee) => assignee.id);

    return this.formBuilder.group({
      ...obj,
      isManual: false,
      description: [obj.description, descriptionValidators],
      title: [obj.title, [Validators.required, validateWhitespaces]],
      keyResults: this.formBuilder.array([]),
      isEdit: false,
      isFetchingDescription: false,
      isFetchingDescriptionError: false,
      isFetchingKeyResultSuggestions: false,
      isFetchedKeyResultSuggestions: false,
      isFetchingKeyResultSuggestionsError: false,
      previousSavedTitle: "",
      previousSavedDescription: "",
      showActions: false,
      prevAssignees: [],
      assignees: [assigneeIds, [Validators.required]],
    });
  }

  private addKeyResultFormGroup(kr: IQuantivePlusMetric): FormGroup {
    return this.formBuilder.group({
      ...kr,
      title: [kr.title, [Validators.required, validateWhitespaces]],
      isEdit: false,
      previousSavedTitle: "",
    });
  }

  private buildForm(objectives: IQuantivePlusObjective[]): void {
    this.getObjectives.clear();

    objectives.forEach((obj) => {
      this.getObjectives.push(this.addObjectiveFormGroup(obj));
    });
  }

  public reset(state: boolean): void {
    this.showConfirmationDialog = state;
  }

  public suggestObjectives(): void {
    this.suggestingObjectivesError = false;
    this.suggestingObjectives = true;
    this.stage = "objectives";

    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();
    const oldObjectives = structuredClone(this.getObjectives.value);
    const assignees = generateAssignees(this.selectedIds, state.assignees.map, state.teams.items);

    const payload: StrategyObjectiveSuggestionPayload = {
      strategy: this.getStrategy.value,
      assignees: assignees,
      existingObjs: oldObjectives,
      nSuggestions: 4,
      subEntityType: "objectives" as PIStateProcessorInstanceSubType,
      documentIds: this.documentIds,
    };

    this.modifySuggestedGoal({ context: payload.strategy, documentIds: this.documentIds, ownerIds: this.selectedIds, state: "initial" })
      .pipe(takeOneUntilDestroyed(this))
      .subscribe();

    this.strategicGuidedOkrFacade
      .getObjectiveSuggestions(payload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (suggestion) => {
          this.buildForm([...oldObjectives, ...suggestion.suggestions.objectives]);

          this.suggestingObjectives = false;
          this.hasSuggestedObjectives = true;
          this.cd.detectChanges();
        },
        error: () => {
          this.suggestingObjectives = false;
          this.suggestingObjectivesError = true;
        },
      });
  }

  public suggestMoreObjectives(): void {
    this.suggestingMoreObjectivesError = false;
    this.suggestingObjectives = true;

    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();
    const oldObjectives = structuredClone(this.getObjectives.value);
    const assignees = generateAssignees(this.selectedIds, state.assignees.map, state.teams.items);

    const payload = {
      strategy: this.getStrategy.value,
      assignees: assignees,
      existingObjs: oldObjectives,
      nSuggestions: 4,
      subEntityType: "objectives" as PIStateProcessorInstanceSubType,
      documentIds: this.documentIds,
    };

    this.strategicGuidedOkrFacade
      .getObjectiveSuggestions(payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (suggestion) => {
          suggestion.suggestions.objectives.forEach((obj) => {
            this.getObjectives.push(this.addObjectiveFormGroup(obj));
          });

          this.suggestingObjectives = false;
          this.cd.detectChanges();
        },
        () => {
          this.suggestingObjectives = false;
          this.suggestingMoreObjectivesError = true;
        }
      );
  }

  public back(): void {
    if (this.stage === "objectives") {
      this.getObjectives.clear();
      this.stage = "initial";

      this.hasSuggestedObjectives = false;
      this.suggestingObjectivesError = false;
      this.suggestingMoreObjectivesError = false;
    }

    if (this.stage === "keyresults") {
      this.getObjectives.value.forEach((obj, i) => {
        const objective = this.getObjectives.at(i);
        objective.patchValue({
          isFetchingKeyResultSuggestions: false,
          isFetchedKeyResultSuggestions: false,
          isFetchingKeyResultSuggestionsError: false,
        });

        const objectiveKeyResults = objective.get("keyResults") as FormArray;
        objectiveKeyResults.clear();
      });

      this.stage = "objectives";
      this.hasSuggestedKrs = false;
    }

    this.reset(false);
  }

  public suggestKeyResults(): void {
    this.suggestingMoreObjectivesError = false;
    this.stage = "keyresults";
    this.suggestingKeyResults = true;

    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();
    const objectives: PIObjectiveSuggestion["suggestions"][] = this.getObjectives.value.map((objective) => {
      const assignees = generateAssignees(this.selectedIds, state.assignees.map, state.teams.items);

      return {
        title: objective.title,
        description: objective.description,
        existingKrs: objective.keyResults.map((kr) => ({ title: kr.title, description: kr.description })),
        assignees: assignees,
        documentIds: this.documentIds,
        nSuggestions: 3,
      };
    });

    this.getObjectives.value.forEach((obj, i) => {
      const objective = this.getObjectives.at(i);
      objective.patchValue({ isFetchingKeyResultSuggestions: true, showActions: false, isFetchingDescriptionError: false });
    });

    const payload: PIKeyResultSuggestionPayload = {
      objectives: objectives,
    };

    const suggestedObjectives = objectives.map((o) => ({
      title: o.title,
      description: o.description,
      assignees: { users: o.assignees.users.map((u) => u.id), teams: o.assignees.teams.map((t) => t.id) },
      keyResults: o.existingKrs,
    }));

    this.modifySuggestedGoal({
      ...this.suggestedGoal,
      suggestedObjectives: suggestedObjectives,
      state: "objectivesSuggested",
    })
      .pipe(takeOneUntilDestroyed(this))
      .subscribe();

    this.strategicGuidedOkrFacade
      .getKeyResultSuggestions(payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (data) => {
          data.suggestions.forEach((obj, i) => {
            const objective = this.getObjectives.at(i);
            objective.patchValue({ isFetchingKeyResultSuggestions: false, isFetchedKeyResultSuggestions: true });
            const krs = this.getObjectives.at(i).get("keyResults") as FormArray;
            obj.keyresults.forEach((kr) => {
              krs.push(this.addKeyResultFormGroup(kr));
            });
          });

          this.suggestingKeyResults = false;
          this.hasSuggestedKrs = true;
          this.cd.detectChanges();
        },
        () => {
          this.suggestingKeyResults = false;
          this.getObjectives.value.forEach((obj, i) => {
            const objective = this.getObjectives.at(i);
            objective.patchValue({ isFetchingKeyResultSuggestions: false, isFetchingKeyResultSuggestionsError: true });
          });
        }
      );
  }

  public confirmObjective(objIndex: number): void {
    const objective = this.getObjectives.at(objIndex);

    if (!objective.value.description) {
      this.suggestObjectiveDescription(objective);
    }
  }

  public refreshObjectiveDescription(objIndex: number): void {
    const objective = this.getObjectives.at(objIndex);
    this.suggestObjectiveDescription(objective);
  }

  private suggestObjectiveDescription(objective: AbstractControl): void {
    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();
    const assignees = generateAssignees(objective.value.assignees, state.assignees.map, state.teams.items);
    const payload: PIObjectiveDescriptionSuggestionPayload = {
      entityId: objective.value.id,
      subEntityType: "description",
      objectives: [
        {
          title: objective.value.title,
          assignees: assignees,
        },
      ],
    };

    objective.patchValue({
      isFetchingDescription: true,
      isEdit: false,
    });

    this.strategicGuidedOkrFacade
      .getObjectiveDescriptionSuggestion(payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (descriptions) => {
          objective.patchValue({
            description: descriptions.suggestions[0].descriptions[0].description,
            isManual: false,
            isEdit: false,
            isFetchingDescription: false,
          });

          objective.get("description").addValidators(descriptionValidators);
          objective.updateValueAndValidity();

          if (this.hasSuggestedKrs) {
            this.suggestMoreKeyResults(this.getObjectives.length - 1);
          }
        },
        () => {
          objective.patchValue({
            isManual: false,
            isEdit: false,
            isFetchingDescription: false,
            isFetchingDescriptionError: true,
          });
        }
      );
  }

  public isFetchingDescription(): boolean {
    return this.getObjectives.value.some((obj) => obj.isFetchingDescription);
  }

  public addObjective(): void {
    this.suggestingObjectivesError = false;
    this.suggestingMoreObjectivesError = false;

    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState>();
    const currentAssignee = state.assignees.map[getCurrentUserId()];

    const objective = {
      title: "",
      description: "",
      assignees: { users: [{ id: currentAssignee.id, name: currentAssignee.name, team: [] }], teams: [] },
      isManual: false,
      isEdit: false,
      isFetchingDescription: false,
      isFetchingKeyResultSuggestions: false,
    };

    const objectiveFormGroup = this.addObjectiveFormGroup(objective);
    objectiveFormGroup.get("description").removeValidators(descriptionValidators);
    objectiveFormGroup.get("description").updateValueAndValidity();

    objectiveFormGroup.patchValue({ isEdit: true, isManual: true });

    this.getObjectives.push(objectiveFormGroup);
  }

  public addKeyResult(objIndex): void {
    const krs = this.getObjectives.at(objIndex).get("keyResults") as FormArray;
    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState>();
    const kr = {
      title: "",
      description: "",
      assignee: state.assignees.map[getCurrentUserId()].name,
      isManual: true,
      isEdit: false,
      format: {
        prefix: "",
        suffix: "%",
        fractionSize: 0,
      },
      initialValue: 0,
      target: 10,
      targetOperator: "at_least" as MetricTargetOperator,
    };

    const krFormGroup = this.addKeyResultFormGroup(kr);
    krFormGroup.patchValue({ isEdit: true });
    krs.push(krFormGroup);
  }

  public suggestMoreKeyResults(objIndex: number): void {
    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();
    const objective = this.getObjectives.at(objIndex);
    objective.patchValue({
      isFetchingKeyResultSuggestions: true,
      isFetchingKeyResultSuggestionsError: false,
      showActions: false,
    });

    const assignees = generateAssignees(this.selectedIds, state.assignees.map, state.teams.items);

    const objectives = {
      title: objective.value.title,
      description: objective.value.description,
      existingKrs: objective.value.keyResults.map((kr) => ({ title: kr.title, description: kr.description })),
      assignees: assignees,
      documentIds: this.documentIds,
      nSuggestions: 3,
    };

    const payload: PIKeyResultSuggestionPayload = {
      objectives: [objectives],
    };

    this.strategicGuidedOkrFacade
      .getKeyResultSuggestions(payload)
      .pipe(untilDestroyed(this))
      .subscribe(
        (data) => {
          data.suggestions.forEach((obj) => {
            const krs = objective.get("keyResults") as FormArray;
            obj.keyresults.forEach((kr) => {
              krs.push(this.addKeyResultFormGroup(kr));
            });
          });

          objective.patchValue({
            isFetchingKeyResultSuggestions: false,
            isFetchedKeyResultSuggestions: true,
          });
          this.cd.detectChanges();
        },
        () => {
          objective.patchValue({
            isFetchingKeyResultSuggestions: false,
            isFetchingKeyResultSuggestionsError: true,
          });
        }
      );
  }

  public isSuggestingMoreKeyResults(): boolean {
    return this.getObjectives.value.some((obj) => obj.isFetchingKeyResultSuggestions);
  }

  public removeKeyResult(event: { metricIndex: number; objIndex: number }): void {
    const krs = this.getObjectives.at(event.objIndex).get("keyResults") as FormArray;
    krs.removeAt(event.metricIndex);
  }

  public removeObjective(objIndex): void {
    this.getObjectives.removeAt(objIndex);
  }

  public addOkrsToWhiteboard(): void {
    this.addingOkrsToWhiteboardError = false;

    if (this.isAddingToWhiteboard) {
      return;
    }

    this.isAddingToWhiteboard = true;

    const currentUser = this.assigneeIdMap[getCurrentUserId()];
    const shapes = this.generateWhiteboardShapes(currentUser.id);
    const payload = {
      name: `${currentUser.name}'s suggestions ${dayjs().format("MM.DD.YYYY HH:mm")}`,
      state: { shapes },
    };

    const goals = this.getObjectives.value;
    const metrics = goals.map((g) => g.keyResults).flat();
    const ids = [...goals, ...metrics].filter((item) => item.id).map((item) => item.id);

    this.whiteboardsFacade
      .createWhiteboard(payload)
      .pipe(
        takeOneUntilDestroyed(this),
        tap(() => this.trackWhiteboardCreation(ids)),
        switchMap((wb) => this.handleWhiteboardNavigation(wb))
      )
      .subscribe({
        next: () => (this.isAddingToWhiteboard = false),
        error: () => {
          this.addingOkrsToWhiteboardError = true;
          this.isAddingToWhiteboard = false;
        },
      });
  }

  public setTopNavBarButtonsConfig(): void {
    const uiButtonBuilder = new UiButtonBuilder();

    const xButton = uiButtonBuilder
      .setKey("") // only icon button
      .setKeyVisibilityMode("always")
      .setType({
        uiType: "iconAndButton",
        buttonType: "default",
        buttonShape: "circle",
        buttonSize: "default",
        iconType: "close",
        iconTheme: "outline",
        iconVisibilityMode: "always",
      })
      .setAction({
        handler: () => this.close(),
      })
      .build();

    this.topNavBarButtonsConfig.buttonsConfig.uiButtons.push(xButton);
  }

  public onUploadedDocumentIds(documentIds: string[]): void {
    this.documentIds = documentIds;
  }

  private modifySuggestedGoal({
    context,
    documentIds = [],
    ownerIds,
    state,
    whiteboardId = "",
    suggestedObjectives,
  }: Partial<UpdateSuggestedGoal>): Observable<SuggestedGoal> {
    if (!this.suggestedGoal) {
      return this.suggestedGoalsRepository.createSuggestedGoal$({ context, documentIds: documentIds, ownerIds }).pipe(
        untilDestroyed(this),
        tap((suggestedGoal) => {
          this.suggestedGoal = suggestedGoal;
          // TODO: This is not working currently but it will be handled in the next PR
          // this.state.go("gtmhub.goalSuggestion", { goalSuggestionId: suggestedGoal.id }, { location: "replace", notify: false });
        })
      );
    }

    return this.suggestedGoalsRepository
      .updateSuggestedGoal$(this.suggestedGoal.id, { context, documentIds: documentIds, ownerIds, state, whiteboardId, suggestedObjectives })
      .pipe(
        untilDestroyed(this),
        tap((suggestedGoal) => (this.suggestedGoal = suggestedGoal))
      );
  }

  private trackWhiteboardCreation(ids: string[]): void {
    this.analyticsService.track(PiTrackingEventsEnum.PiSuggestionAccepted, {
      flowName: this.flowName,
      flowId: this.flowId,
      userId: getCurrentUserId(),
      goalAndMetricSuggestionIds: ids,
    });
  }

  private handleWhiteboardNavigation(whiteboard: Whiteboard): Observable<void> {
    return this.modifySuggestedGoal({
      ...this.suggestedGoal,
      whiteboardId: whiteboard.id,
      state: "whiteboardGenerated",
    }).pipe(
      takeOneUntilDestroyed(this),
      switchMap(() => this.navigateToWhiteboard(whiteboard.id))
    );
  }

  private navigateToWhiteboard(whiteboardId: string): Observable<void> {
    window.open(`/#/whiteboards/${whiteboardId}/`, "_self");
    return of(null);
  }

  private generateWhiteboardShapes(currentUserId: string): unknown[] {
    const objectives = structuredClone(this.objectiveForm.value.objectives);

    // creating 2d/matrix array so that we can later use rows/cols to move on coordinate system
    const objectives2d = [];
    while (objectives.length) objectives2d.push(objectives.splice(0, 4));

    const paddingTop = 60;
    const rowHight = 30;
    const draftGoalAndDraftMetricRows = 3; // adding the goal title and 'add metric' button + 1 metric index bcs they start at 0

    let maxRows = 0;

    const noteWidth = 450;
    const baseXCoord = noteWidth + 70;
    const shapes = [this.generateNote(currentUserId, noteWidth)];

    for (const [row, objectives] of objectives2d.entries()) {
      let maxColRows = 0;

      for (const [col, objective] of objectives.entries()) {
        const ownerIds = objective.assignees;

        const metrics = this.generateDraftMetrics(objective, ownerIds);
        const rows = getMetricRowsNumber(metrics) + draftGoalAndDraftMetricRows;

        if (rows >= maxColRows) {
          maxColRows = rows;
        }

        const y = row === 0 ? 0 : maxRows * rowHight;
        const position = { x: col * 360 + baseXCoord, y: row === 0 ? y : y + row * paddingTop };
        const goal = createDraftGoal(objective.title, ownerIds, position, this.currentSession?.id || "");
        const draftGoal = { ...goal, metrics };

        shapes.push(draftGoal);
      }

      maxRows += maxColRows;
    }

    return shapes;
  }

  private generateDraftMetrics(objective, ownerIds: string[]): IDraftGoalMetricState[] {
    return objective.keyResults.map((metric) =>
      createDraftMetric({
        name: metric.title,
        ownerIds,
        description: metric.description,
        initialValue: metric.initial,
        target: metric.target,
        targetOperator: metric.direction,
      })
    );
  }

  private generateNote(currentUserId: string, noteWidth: number): unknown {
    const note = createNewNote({ x: 0, y: 0 }, currentUserId, 0);
    note.content = this.getStrategy.value;
    note.size.width = noteWidth;
    note.size.height = 300;
    return note;
  }
}
